import { rolePermissionData } from '../../role-permission/role-permission';

const getPathByName = (title) => {
	return rolePermissionData
		.find((e) => e.title === title)
		.routes.map((e) => e.path);
};
export const adminSidebar = [
	{
		id: 0,
		name: 'All Users',
		path: '/admin/all-user',
		icon: 'user',
		arrow: true,
		hr: false,
		permissionRoute: [...getPathByName('All User')],
		subMenu: [],
	},
	{
		id: 1,
		name: 'Merchants',
		path: false,
		icon: 'vendor',
		arrow: true,
		hr: false,
		permissionRoute: [...getPathByName('Merchant')],

		subMenu: [
			{
				permissionSubRoute: 'add-vendor',
				name: 'Add Merchant',
				path: '/admin/vendor/add-new',
			},
			{
				permissionSubRoute: 'all-vendor',
				name: 'All Merchant',
				path: '/admin/vendors/all-vendors',
			},
			{
				permissionSubRoute: 'active-vendor',
				name: 'Active Merchant',
				path: '/admin/vendors/active-vendor',
			},
			{
				permissionSubRoute: 'pending-vendor',
				name: 'Pending Merchant',
				path: '/admin/vendors/pending-vendor',
			},
		],
	},
	{
		id: 2,
		name: 'Drop Shipper',
		path: false,
		icon: 'user',
		arrow: true,
		hr: false,
		permissionRoute: [...getPathByName('Drop Shipper')],

		subMenu: [
			{
				permissionSubRoute: 'add-affiliate',
				name: 'Add Drop Shipper',
				path: '/admin/affiliator/add-new',
			},
			{
				permissionSubRoute: 'all-affiliate',
				name: 'All Drop Shipper',
				path: '/admin/all-affiliates',
			},
			{
				permissionSubRoute: 'active-affiliate',
				name: 'Active Drop Shipper',
				path: '/admin/active-affiliates',
			},
			{
				permissionSubRoute: 'pending-affiliate',
				name: 'Pending Drop Shipper',
				path: '/admin/pending-affiliates',
			},
		],
	},
	{
		id: 24,
		name: 'Users',
		path: false,
		icon: 'user',
		arrow: true,
		hr: true,
		permissionRoute: [...getPathByName('User')],

		subMenu: [
			{
				permissionSubRoute: 'add-user',
				name: 'Add User',
				path: '/admin/user/add-new',
			},
			{
				permissionSubRoute: 'all-user',
				name: 'All Users',
				path: '/admin/all-users',
			},
			{
				permissionSubRoute: 'active-user',
				name: 'Active Users',
				path: '/admin/active-users',
			},
			{
				permissionSubRoute: 'pending-user',
				name: 'Pending Users',
				path: '/admin/pending-users',
			},
		],
	},
	{
		id: 5,
		name: 'Manage Categories',
		path: false,
		icon: 'brand',
		arrow: true,
		hr: false,
		permissionRoute: [...getPathByName('Manage Category')],

		subMenu: [
			{
				permissionSubRoute: 'category',
				name: 'Categories',
				path: '/admin/main-category',
			},
			{
				permissionSubRoute: 'sub-category',
				name: 'Sub Categories',
				path: '/admin/sub-category',
			},
		],
	},

	{
		id: 6,
		name: 'Brand',
		path: '/admin/brand-list',
		icon: 'brand',
		arrow: false,
		hr: true,
		permissionRoute: [...getPathByName('Brand')],

		subMenu: [],
	},
	{
		id: 3,
		name: 'Merchant Products',
		path: false,
		icon: 'product',
		arrow: true,
		hr: false,
		permissionRoute: [...getPathByName('Merchant Products')],
		activeRoute: ['product-view-edited'],
		subMenu: [
			{
				permissionSubRoute: 'all-products',
				name: 'All Products',
				path: '/admin/vendor-product-list',
			},
			{
				permissionSubRoute: 'active-products',
				name: 'Active Products',
				path: '/admin/vendor-active-product',
			},
			{
				permissionSubRoute: 'pending-products',
				name: 'Pending Products',
				path: '/admin/vendor-pending-product',
			},
			{
				permissionSubRoute: 'edit-products',
				name: 'Edited Products',
				path: '/admin/vendor-edited-product',
			},
			{
				permissionSubRoute: 'rejected-product',
				name: 'Rejected Products',
				path: '/admin/vendor-rejected-product',
			},
		],
	},
	{
		id: 4,
		name: 'Drop Shipper Request',
		path: false,
		icon: 'affiliateRequest',
		arrow: true,
		hr: false,
		permissionRoute: [...getPathByName('Drop Shipper Request')],

		subMenu: [
			{
				permissionSubRoute: 'all-request',
				name: 'All Request',
				path: '/admin/affiliate-product-request',
			},
			{
				permissionSubRoute: 'active-request',
				name: 'Active Request',
				path: '/admin/affiliate-active-request',
			},
			{
				permissionSubRoute: 'pending-request',
				name: 'Pending Request',
				path: '/admin/affiliate-pending-request',
			},
			{
				permissionSubRoute: 'rejected-request',
				name: 'Rejected Request',
				path: '/admin/affiliate-rejected-request',
			},
		],
	},
	{
		id: 7,
		name: 'Manage Orders',
		path: false,
		icon: 'order',
		arrow: true,
		hr: true,
		permissionRoute: [...getPathByName('Manage Order')],

		subMenu: [
			{
				permissionSubRoute: 'all-order',
				name: 'Order All',
				path: '/admin/orders-history-all',
			},
			{
				permissionSubRoute: 'order-hold',
				name: 'Order Holding',
				path: '/admin/orders-history-hold',
			},
			{
				permissionSubRoute: 'order-pending',
				name: 'Order Pending',
				path: '/admin/orders-history-pending',
			},
			{
				permissionSubRoute: 'order-received',
				name: 'Order Received',
				path: '/admin/orders-history-received',
			},
			{
				permissionSubRoute: 'order-processing',
				name: 'Product Processing',
				path: '/admin/orders-history-processing',
			},
			{
				permissionSubRoute: 'order-ready',
				name: 'Product Ready',
				path: '/admin/orders-history-ready',
			},
			{
				permissionSubRoute: 'delivery-processing',
				name: 'Delivery Processing',
				path: '/admin/orders-history-progress',
			},
			{
				permissionSubRoute: 'product-delivered',
				name: 'Product Delivered',
				path: '/admin/orders-history-delivered',
			},
			{
				permissionSubRoute: 'order-return',
				name: 'Product Return',
				path: '/admin/orders-history-return',
			},
			{
				permissionSubRoute: 'order-cancel',
				name: 'Order Cancel',
				path: '/admin/orders-history-cancel',
			},
		],
	},
	{
		id: 27,
		name: 'Service Topic',
		path: false,
		icon: 'serviceTopic',
		arrow: true,
		hr: false,
		permissionRoute: [...getPathByName('Service Topic')],
		subMenu: [
			{
				permissionSubRoute: 'service-category',
				name: 'Service Category',
				path: '/admin/service-category',
			},
			{
				permissionSubRoute: 'service-sub-category',
				name: 'Sub-Category',
				path: '/admin/service-sub-category',
			},
		],
	},
	{
		id: 22,
		name: 'Manage Service',
		path: '/admin/service',
		icon: 'manageService',
		arrow: false,
		hr: false,
		permissionRoute: [...getPathByName('Manage Service')],
		subMenu: [],
	},
	{
		id: 23,
		name: 'Service Order',
		path: '/admin/service-order',
		icon: 'order',
		arrow: true,
		hr: true,
		permissionRoute: [...getPathByName('Service Order')],

		subMenu: [],
	},
	{
		id: 21,
		name: 'Advertiser',
		path: false,
		icon: 'advertise',
		arrow: false,
		hr: true,
		permissionRoute: [...getPathByName('Advertiser')],

		subMenu: [
			{
				permissionSubRoute: 'advertise-utility',
				name: 'Advertise Utility',
				path: '/admin/advertise-utility',
			},

			{
				permissionSubRoute: 'all-advertiser',
				name: 'All Advertiser',
				path: '/admin/advertise',
			},
		],
	},

	{
		id: 20,
		name: 'Support',
		path: false,
		icon: 'support',
		arrow: false,
		hr: true,
		count: false,
		permissionRoute: [
			...getPathByName('Support Topic'),
			...getPathByName('Support'),
		],

		subMenu: [
			{
				permissionSubRoute: 'support',
				name: 'Support',
				path: '/admin/all-support',
			},
			{
				permissionSubRoute: 'support-category',
				name: 'Support Cateogory',
				path: '/admin/support-cateogory',
			},
			{
				permissionSubRoute: 'support-problem-topic',
				name: 'Problem Topic',
				path: '/admin/support-problem-topic',
			},
		],
	},

	{
		id: 17,
		name: 'Subscription',
		path: '/admin/subscription',
		icon: 'subscription',
		arrow: false,
		hr: false,
		permissionRoute: [...getPathByName('Subscription')],
		subMenu: [],
	},

	{
		id: 19,
		name: 'Coupon',
		path: false,
		icon: 'coupons',
		arrow: false,
		hr: false,
		permissionRoute: [...getPathByName('Coupon')],

		subMenu: [
			{
				permissionSubRoute: 'create-coupon',
				name: 'Create Coupon',
				path: '/admin/create-coupon/null',
			},
			{
				permissionSubRoute: 'active-coupon',
				name: 'Active Coupon',
				path: '/admin/all-coupon',
			},
			{
				permissionSubRoute: 'request-coupon',
				name: 'Request Coupon',
				path: '/admin/all-request-coupon',
			},
			{
				permissionSubRoute: 'rejected-coupon',
				name: 'Rejected Request',
				path: '/admin/all-request-rejected-coupon',
			},
		],
	},

	{
		id: 25,
		name: 'Membership',
		path: '/admin/vendor-affiliate-membership',
		icon: 'membership',
		arrow: true,
		hr: false,
		permissionRoute: [...getPathByName('Membership')],

		subMenu: [],
	},
	{
		id: 28,
		name: 'Withdraw',
		path: '/admin/users-withdraw',
		icon: 'wallet',
		arrow: true,
		hr: false,
		permissionRoute: [...getPathByName('Withdraw')],

		subMenu: [],
	},
	{
		id: 18,
		name: 'Users Response',
		path: '/admin/contact-message',
		icon: 'user',
		arrow: false,
		hr: false,
		permissionRoute: [...getPathByName('Users Response')],

		subMenu: [],
	},
	{
		id: 29,
		name: 'Setting',
		path: '/admin/setting',
		icon: 'setting',
		arrow: false,
		hr: true,
		permissionRoute: [...getPathByName('Setting')],

		subMenu: [],
	},
	{
		id: 10,
		name: 'Home Content',
		path: false,
		icon: 'homeContent',
		arrow: true,
		hr: false,
		permissionRoute: [...getPathByName('Home Content')],

		subMenu: [
			{
				permissionSubRoute: 'home-service',
				name: 'Service',
				path: '/admin/content/service',
			},
			{
				permissionSubRoute: 'organization',
				name: 'Organization',
				path: '/admin/organization',
			},
			{
				permissionSubRoute: 'it-service',
				name: 'It Service',
				path: '/admin/it-service',
			},
			{
				permissionSubRoute: 'organization-two',
				name: 'Organization Two',
				path: '/admin/organization-two',
			},
			{
				permissionSubRoute: 'partner',
				name: 'Partner',
				path: '/admin/partner',
			},
			{
				permissionSubRoute: 'content',
				name: 'Contact',
				path: '/admin/footer',
			},
			{
				permissionSubRoute: 'home-update-content',
				name: 'Update Content',
				path: '/admin/update-home-content',
			},
		],
	},
	{
		id: 11,
		name: 'About Content',
		path: false,
		icon: 'homeContent',
		arrow: true,
		hr: false,
		permissionRoute: [...getPathByName('About Content')],

		subMenu: [
			{
				permissionSubRoute: 'companions',
				name: 'Companions',
				path: '/admin/update-companions',
			},
			{
				permissionSubRoute: 'mission',
				name: 'Missions',
				path: '/admin/missions',
			},
			{
				permissionSubRoute: 'testimonial',
				name: 'Testimonial',
				path: '/admin/testimonial',
			},
			{
				permissionSubRoute: 'members',
				name: 'Members',
				path: '/admin/members',
			},
			{
				permissionSubRoute: 'abount-update-content',
				name: 'Update Content',
				path: '/admin/update-about-content',
			},
		],
	},
	{
		id: 12,
		name: 'General Content',
		path: false,
		icon: 'homeContent',
		arrow: true,
		hr: false,
		permissionRoute: [...getPathByName('General Content')],

		subMenu: [
			{
				permissionSubRoute: 'general-update-content',
				name: 'Update Content',
				path: '/admin/update-general-content',
			},
		],
	},
	{
		id: 13,
		name: 'Advertise Content',
		path: false,
		icon: 'homeContent',
		arrow: true,
		hr: false,
		permissionRoute: [...getPathByName('Advertise Content')],

		subMenu: [
			{
				permissionSubRoute: 'faq',
				name: 'FAQ',
				path: '/admin/faq',
			},
			{
				permissionSubRoute: 'advertise-update-content',
				name: 'Update Content',
				path: '/admin/update-advertise-content',
			},
		],
	},
	{
		id: 14,
		name: 'Service Content',
		path: false,
		icon: 'homeContent',
		arrow: true,
		hr: true,
		permissionRoute: [...getPathByName('Service Content')],

		subMenu: [
			{
				permissionSubRoute: 'service-update-content',
				name: 'Update Content',
				path: '/admin/update-service-content',
			},
		],
	},

	{
		id: 26,
		name: 'Role Permission',
		path: '/admin-dashboard/role-permission',
		icon: 'rolePermission',
		arrow: true,
		hr: true,
		permissionRoute: [...getPathByName('Role')],

		subMenu: [],
	},
];
