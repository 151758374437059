import { vendorRoutes } from '../../../routes/all-routes';
import { NAME } from '../../action';

export const vendorMenu = [
	{
		id: '1',
		name: 'Product',
		path: false,
		icon: 'product',
		arrow: true,
		hr: false,
		activeRoute: ['product-view', '/product/'],
		subMenu: [
			{
				name: 'Add Product',
				path: vendorRoutes.productAdd + 'create',
				permissionRoute: 'add_product',
			},
			{
				id: '1-2',
				name: 'All Product',
				path: vendorRoutes.allProduct,
				permissionRoute: 'all_product',
			},

			{
				id: '1-3',
				name: 'Active Product',
				path: vendorRoutes.activeProduct,
				permissionRoute: 'active_product',
			},
			{
				id: '1-4',
				name: 'Pending Product',
				path: vendorRoutes.pendingProduct,
				permissionRoute: 'pending_product',
			},
			{
				id: '1-5',
				name: 'Edited Product',
				path: vendorRoutes.editedProduct,
				permissionRoute: 'edit_product',
			},
			{
				id: '1-6',
				name: 'Rejected Product',
				path: vendorRoutes.rejectedProduct,
				permissionRoute: 'reject_product',
			},
		],
	},

	{
		id: '2',
		name: 'Product Orders',
		path: false,
		activeRoute: ['new-order', 'orders-history-view', '/order-exchange/'],
		icon: 'order',
		arrow: true,
		hr: false,
		subMenu: [
			{
				id: '2-2',
				name: 'New Order',
				path: vendorRoutes.orderNew,
				permissionRoute: 'add_order',
			},
			{
				id: '2-1',
				name: 'All Orders',
				path: vendorRoutes.orderAll,
				permissionRoute: 'all_order',
			},

			{
				id: '2-3',
				name: 'Orders Hold',
				path: vendorRoutes.orderHold,
				permissionRoute: 'hold_order',
			},
			{
				id: '2-4',
				name: 'Orders Pending',
				path: vendorRoutes.orderPending,
				permissionRoute: 'pending_order',
			},
			{
				id: '2-5',
				name: 'Orders Received',
				path: vendorRoutes.orderReceived,
				permissionRoute: 'receive_order',
			},
			{
				id: '2-10',
				name: 'Product Processing',
				path: vendorRoutes.orderProcessing,
				permissionRoute: 'order_processing',
			},
			{
				id: '2-11',
				name: 'Product Ready',
				path: vendorRoutes.orderReady,
				permissionRoute: 'order_ready',
			},
			{
				id: '2-6',
				name: 'Delivery Processing',
				path: vendorRoutes.orderProgress,
				permissionRoute: 'delivery_processing',
			},
			{
				id: '2-7',
				name: 'Product Delivered',
				path: vendorRoutes.orderDelivered,
				permissionRoute: 'delivery_order',
			},
			{
				id: '2-9',
				name: 'Product Return',
				path: vendorRoutes.orderReturn,
				permissionRoute: 'order_return',
			},
			{
				id: '2-8',
				name: 'Order Cancel',
				path: vendorRoutes.orderCancel,
				permissionRoute: 'cancel_order',
			},
		],
	},

	{
		id: '3',
		name: 'Pos Sales',
		activeRoute: ['sales/-invoice'],
		path: false,
		icon: 'sales',
		arrow: true,
		hr: false,
		subMenu: [
			{
				id: '3-1',
				name: 'Customer',
				path: vendorRoutes.customer,
				permissionRoute: 'customer',
			},
			{
				id: '3-2',
				name: 'Pos Sales',
				path: vendorRoutes.posSales,
				permissionRoute: 'add_pos_sale',
			},

			{
				id: '3-3',
				name: 'Manage Sales',
				path: vendorRoutes.posManageSales,
				permissionRoute: 'all_pos_sale',
			},
			{
				id: '3-4',
				name: 'Payment History',
				path: vendorRoutes.posSalesHistory,
				permissionRoute: 'payment_history_pos_sale',
			},
		],
	},
	{
		id: '4',
		name: 'Purchase',
		activeRoute: ['purchase-invoice'],
		path: false,
		icon: 'cart',
		arrow: false,
		hr: false,
		subMenu: [
			{
				id: '4-2',
				name: 'Payment History',
				path: vendorRoutes.paymentHistory,
				permissionRoute: 'payment_history_purchase',
			},
			{
				id: '4-3',
				name: 'New Purchase',
				path: vendorRoutes.purchase,
				permissionRoute: 'add_purchase',
			},
			{
				id: '4-4',
				name: 'Manage Purchase',
				path: vendorRoutes.managePurchase,
				permissionRoute: 'all_purchase',
			},
		],
	},
	{
		id: '5',
		name: 'Barcode',
		path: false,
		icon: 'income',
		arrow: true,
		hr: true,
		subMenu: [
			{
				id: '5-1',
				name: 'Barcode Generator',
				path: vendorRoutes.barcodeGenerate,
				permissionRoute: 'barcode_generate',
			},
			{
				id: '5-2',
				name: 'Manage Barcode',
				path: vendorRoutes.barcodeManage,
				permissionRoute: 'barcode_manage',
			},
		],
	},
	{
		id: '5-1-1',
		name: 'Damage Products',
		path: false,
		icon: 'income',
		arrow: true,
		hr: true,
		subMenu: [
			{
				id: '5-1-1-1',
				name: 'Create Damage',
				path: vendorRoutes.damageProduct,
				permissionRoute: 'create_damage',
			},
			{
				id: '5-1-1-2',
				name: 'Damage Products',
				path: vendorRoutes.damageProductList,
				permissionRoute: 'damage_list',
			},
		],
	},
	{
		id: '6',
		name: 'Setting',
		path: false,
		icon: 'setting',
		arrow: true,
		hr: true,
		subMenu: [
			{
				id: '6-1',
				name: 'Order Source',
				path: vendorRoutes.orderSource,
				permissionRoute: 'source',
			},
			{
				id: '6-1-1',
				name: 'Delivery Company',
				path: vendorRoutes.deliveryCompany,
				permissionRoute: 'delivery_company',
			},
			{
				id: '6-1-1',
				name: 'Delivery Area',
				path: vendorRoutes.deliveryArea,
				permissionRoute: 'delivery_area',
			},
			{
				id: '6-1-1',
				name: 'Pickup Area',
				path: vendorRoutes.pickupArea,
				permissionRoute: 'pickup_area',
			},
			{
				id: '6-2',
				name: 'Payment Methods',
				path: vendorRoutes.paymentMethods,
				permissionRoute: 'payment_method',
			},
			{
				id: '6-3',
				name: 'Invoice Generate',
				path: vendorRoutes.invoiceGenerate,
				permissionRoute: 'invoice_generate',
			},
		],
	},
	{
		id: '6-1',
		name: 'Setup First',
		path: false,
		icon: 'setUp',
		arrow: true,
		hr: true,
		subMenu: [
			{
				id: '4-1',
				name: 'Supplier',
				path: vendorRoutes.supplier,
				permissionRoute: 'supplier',
			},
			{
				id: '1-7',
				name: 'Warehouse',
				path: vendorRoutes.warehouse,
				permissionRoute: 'warehouse',
			},
			{
				id: '1-7-1',
				name: 'Delivery Charge',
				path: vendorRoutes.deliveryCharge,
				permissionRoute: 'delivery_charge',
			},
			{
				id: '1-8',
				name: NAME.unit.name,
				path: vendorRoutes.unit,
				permissionRoute: 'unit',
			},
			{
				id: '1-9',
				name: NAME.utility.name,
				path: vendorRoutes.utility,
				permissionRoute: 'color',
			},
			{
				id: '1-10',
				name: NAME.variation.name,
				path: vendorRoutes.variation,
				permissionRoute: 'variation',
			},
		],
	},
	{
		id: '7',
		name: 'Drop Shipper Request',
		path: false,
		activeRoute: ['product-view-requested'],
		icon: 'affiliateRequest',
		arrow: true,
		hr: true,
		subMenu: [
			{
				id: '7-1',
				name: 'All Request',
				path: vendorRoutes.affiliateAllRequest,
				permissionRoute: 'all_request',
			},
			{
				id: '7-2',
				name: 'Active Request',
				path: vendorRoutes.affiliateActiveRequest,
				permissionRoute: 'active_request',
			},
			{
				id: '7-3',
				name: 'Pending Request',
				path: vendorRoutes.affiliatePendingRequest,
				permissionRoute: 'pending_request',
			},
			{
				id: '7-4',
				name: 'Rejected Request',
				path: vendorRoutes.affiliateRejectedRequest,
				permissionRoute: 'reject_request',
			},
			// {
			// 	id: '7-5',
			// 	name: 'Expired Request',
			// 	path: vendorRoutes.affiliateExpiredRequest,
			// 	permissionRoute: 'expired_request',
			// },
		],
	},

	{
		id: '8',
		name: 'Return List',
		activeRoute: ['purchase/-return/view'],
		path: false,
		icon: 'homeContent',
		arrow: true,
		hr: true,
		subMenu: [
			{
				id: '8-1',
				name: 'Purchase Return',
				path: vendorRoutes.purchaseReturn,
				permissionRoute: 'purchase_return',
			},
			{
				id: '8-2',
				name: 'Sale Return',
				path: vendorRoutes.saleReturn,
				permissionRoute: 'sale_return',
			},
			{
				id: '8-3',
				name: 'Add Wastage',
				path: vendorRoutes.addWastage,
				permissionRoute: 'add_wastage',
			},
			{
				id: '8-4',
				name: 'Wastage List',
				path: vendorRoutes.wastageList + 'all',
				permissionRoute: 'all_wastage',
			},
		],
	},
	{
		id: '9',
		name: 'Report',
		path: false,
		icon: 'report',
		arrow: false,
		hr: false,
		subMenu: [
			{
				id: '9-1',
				name: 'Stock Report',
				path: vendorRoutes.stokeReport,
				permissionRoute: 'stock_report',
			},
			{
				id: '9-2',
				name: 'Sales Report',
				path: vendorRoutes.salesReport,
				permissionRoute: 'sales_report',
			},
			{
				id: '9-3',
				name: 'Due Sales Report',
				path: vendorRoutes.dueSalesReport,
				permissionRoute: 'due_sales_report',
			},
			{
				id: '9-4',
				name: 'Purchase Report',
				path: vendorRoutes.purchaseReport,
				permissionRoute: 'purchase_report',
			},
			{
				id: '9-5',
				name: 'Warehouse Report',
				path: vendorRoutes.warehouseReport,
				permissionRoute: 'warehouse_report',
			},
			{
				id: '9-6',
				name: 'Stock Shortage Report',
				path: vendorRoutes.stockShortage,
				permissionRoute: 'stock_shortage_report',
			},
			{
				id: '9-7',
				name: 'Top Repeat Customer',
				path: vendorRoutes.topRepeatCustomer,
				permissionRoute: 'top_repeat_customer',
			},
			{
				id: '9-9',
				name: 'Daily Sales Report',
				path: vendorRoutes.salesReportDaily,
				permissionRoute: 'sales_report_daily',
			},
		],
	},

	{
		id: '10',
		name: 'Service & Orders',
		path: false,
		activeRoute: ['vendors-dashboard/service-order/'],
		icon: 'serviceTopic',
		arrow: true,
		hr: true,
		subMenu: [
			{
				id: '10-1',
				name: 'Create Service',
				path: vendorRoutes.createService,
				permissionRoute: 'create_service',
			},
			{
				id: '10-2',
				name: 'All Service',
				path: vendorRoutes.allService,
				permissionRoute: 'all_service',
			},
			{
				id: '10-3',
				name: 'Service Order',
				path: vendorRoutes.orderService,
				permissionRoute: 'service_order',
			},
		],
	},

	{
		id: '11',
		name: 'My Coupon',
		path: vendorRoutes.coupon,
		permissionRoute: 'coupon',
		icon: 'coupons',
		arrow: true,
		hr: false,
		subMenu: [],
	},
	{
		id: '12',
		name: 'Membership',
		path: vendorRoutes.membership,
		permissionRoute: 'membership',
		activeRoute: ['/vendors-dashboard/renew/checkout/'],
		icon: 'membership',
		arrow: true,
		hr: true,
		subMenu: [],
	},
	{
		id: '13',
		name: 'Purchase Advertiser',
		path: vendorRoutes.advertise,
		activeRoute: ['advertise-view'],
		permissionRoute: 'advertiser',
		// permissionRoute: [...getPathByName('advertiser')],
		icon: 'cart',
		arrow: true,
		hr: true,
		subMenu: [],
	},
	{
		id: '14',
		name: 'Purchased Service',
		path: false,
		activeRoute: ['my-service-order'],
		icon: 'user',
		arrow: true,
		hr: true,
		subMenu: [
			{
				id: '14-1',
				name: 'All Order',
				path: vendorRoutes.myServiceOrder,
				permissionRoute: 'all_service_order',
			},
			// {
			// 	id: '14-2',
			// 	name: 'Pending Order',
			// 	path: '/vendor-dashboard/pending-order',
			// 	permissionRoute: 'pending_service_order',
			// },
			// {
			// 	id: '14-3',
			// 	name: 'Progress Order',
			// 	path: '/vendor-dashboard/progress-order',
			// 	permissionRoute: 'progress_service_order',
			// },
			// {
			// 	id: '14-4',
			// 	name: 'Hold Order',
			// 	path: '/vendor-dashboard/hold-order',
			// 	permissionRoute: 'hold_service_order',
			// },
			// {
			// 	id: '14-5',
			// 	name: 'Cancel Order',
			// 	path: '/vendor-dashboard/cancel-order',
			// 	permissionRoute: 'cancel_service_order',
			// },
		],
	},

	{
		id: '15',
		name: 'Balance',
		path: false,
		icon: 'wallet',
		arrow: true,
		hr: true,
		subMenu: [
			{
				id: '15-1',
				name: 'Recharge',
				path: vendorRoutes.recharge,
				permissionRoute: 'recharge',
			},
			{
				id: '15-2',
				name: 'History',
				path: vendorRoutes.history,
				permissionRoute: 'recharge_history',
			},
			{
				id: '15-3',
				name: 'Withdraw Income',
				path: vendorRoutes.withdraw,
				permissionRoute: 'withdraw',
			},
		],
	},
	{
		id: '16',
		name: 'Support',
		path: false,
		icon: 'support',
		count: '1',

		arrow: true,
		activeRoute: ['single-support-ticket'],
		hr: true,
		subMenu: [
			{
				id: '16-1',
				name: 'Create Ticket',
				path: vendorRoutes.createSupport,
				permissionRoute: 'create_support',
			},
			{
				id: '16-2',
				name: 'All Support',
				path: vendorRoutes.allSupport,
				permissionRoute: 'all_support',
			},
		],
	},
	{
		id: '17',
		name: 'Chat',
		path: vendorRoutes.chat,
		icon: 'support',
		permissionRoute: 'chat',
		arrow: true,
		hr: true,
		subMenu: [],
	},
	{
		id: '18',
		name: 'Employees',
		permissionRoute: 'employee',
		path: vendorRoutes.employee,
		icon: 'rolePermission',
		arrow: true,
		hr: true,
		subMenu: [],
	},
];
