import { useEffect } from 'react';
import { useApiAutoFetch } from '../../../api/common';

export const useCountSupport = (sidebarMenu) => {
	const { response: messageCount } = useApiAutoFetch({
		endPoint: 'ticket-replay-count',
	});

	useEffect(() => {
		sidebarMenu.find((menu) => {
			return menu.name === 'Support' ? messageCount?.msgCount : false;
		});
	}, [messageCount, sidebarMenu]);

	return { sidebarMenu };
};
