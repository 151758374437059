import { useMutation, useQuery } from 'react-query';
import { http, multipartConfig } from '../../components/action/axiosInstance';
import { useContext, useEffect, useRef, useState } from 'react';
import { UseAuth } from '../../auth/AuthContext';
import tost from '../../components/action/tost';
import { DeletePopUP, popUp } from '../../components/action/DeletePopUP';

/*
get api call
const { response, isLoading, refetch } = useApi({
	endPoint: apisVendor.unit.get,
});
*/
export const useApi = ({
	endPoint,
	page = '',
	search = '',
	refetchOnWindowFocus = true,
}) => {
	const { logout } = useContext(UseAuth);
	const { data, refetch, isLoading, error } = useQuery({
		queryKey: [endPoint, page, search],
		queryFn: () => http.get(`${endPoint}?page=${page}&search=${search}`),
		retry: false,
		refetchOnWindowFocus,
	});
	const response = data?.data;

	if (
		error?.response?.data?.message === 'Unauthenticated.' ||
		(response?.status === 403 && response?.message === 'Account is not active')
	) {
		logout();
	}

	return { response, refetch, isLoading };
};
/*
get api call before select something like id 
const { response: statusResponse, setId } = useApiForId({
		endPoint: apisVendor.purchase.getSupplierProduct,
	});

	setId(e.value);
*/

// this is for auto fetch api 20000
// use same as useApi above
export const useApiAutoFetch = ({ endPoint, page = '', search = '' }) => {
	const { logout } = useContext(UseAuth);
	const { data, refetch, isLoading, error } = useQuery({
		queryKey: [endPoint, page, search],
		queryFn: () => http.get(`${endPoint}?page=${page}&search=${search}`),
		refetchInterval: 20000,
	});
	const response = data?.data;

	if (error?.response?.data?.message === 'Unauthenticated.') {
		logout();
	}

	return { response, refetch, isLoading };
};

export const useApiForId = ({ endPoint, page = '', search = '' }) => {
	const [id, setId] = useState('');
	const { logout } = useContext(UseAuth);
	const { data, refetch, isLoading, error } = useQuery({
		queryKey: [endPoint, id, page, search],
		queryFn: () => {
			return http.get(`${endPoint + id}?page=${page}&search=${search}`);
		},
		refetchOnWindowFocus: false,
		retry: false,
		enabled: !!id,
	});
	const response = data?.data;
	if (error?.response?.data?.message === 'Unauthenticated.') {
		logout();
	}
	return { response, refetch, isLoading, setId, id };
};

// ===================================================================

export const useInterval = (callback, delay) => {
	const savedCallback = useRef();

	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	useEffect(() => {
		const tick = () => {
			savedCallback.current();
		};
		if (delay !== null) {
			const intervalId = setInterval(tick, delay);
			return () => clearInterval(intervalId);
		}
	}, [delay]);
};

// useInterval(() => {
// 	refetch();
// }, 10000);
// ===================================================================
export const useSearch = ({ endPoint, page = '', search = '' }) => {
	const [id, setId] = useState('');
	const { logout } = useContext(UseAuth);
	const { data, refetch, isLoading, error } = useQuery({
		queryKey: [endPoint, id, page, search],
		queryFn: () => {
			return http.get(`${endPoint + id}`);
		},
		refetchOnWindowFocus: false,
		retry: false,
		enabled: !!id,
	});
	const response = data?.data;

	if (error?.response?.data?.message === 'Unauthenticated.') {
		logout();
	}
	return { response, refetch, isLoading, setId, id };
};

// [endPoint, id, page, search],
// 		() => {
// 			return http.get(`${endPoint + id}?page=${page}&search=${search}`);
// 		}
/* ----------------------------------------------------------------------
	post api call
	how to  use
	------------------- 
	=>  import like this 
				const { loading, mutate } = usePost(refetch);

	=>  onSubmit Function call like this 
			onSubmit={handelSubmit}

	=>  disable use = {loading}

	=> Example
	// start 
			const handelSubmit = async (e) => {
				e.preventDefault();

				const cb = async () => {
					await mutate(
						{
							data: { ...state?.data, unit_slug: state.data.unit_name },
							endPoint: apisAdmin.unit.post,
						},
						{
							onSuccess: () => {
								e.target.reset();
							},
						}
					);
				};
				
				popUp('Create', 'Are You Sure?', 'warning', cb);
			};
	// end 
*/

export const usePost = (refetch, multipart = false) => {
	const { logout } = useContext(UseAuth);

	const {
		isLoading: loading,
		mutate,
		error,
	} = useMutation({
		mutationFn: async (data) => {
			return await http.post(
				data?.endPoint,
				data?.data,
				multipart && multipartConfig
			);
		},

		// for global post error
		onError: (error) => {
			// refetch();

			tost(error?.message);
			if (error?.response?.data?.message === 'Unauthenticated.') {
				logout();
			}
		},

		// for global post success
		onSuccess: ({ data }) => {
			refetch && refetch();
			tost(data?.message);
			if (data?.response?.data?.message === 'Unauthenticated.') {
				logout();
			}
		},
	});

	if (error?.response?.data?.message === 'Unauthenticated.') {
		logout();
	}

	return { loading, mutate };
};

/*
use get api for status change 
how to use 

	<TableStatus
		status={data?.status}
		text={data?.status}
		style={{ cursor: 'pointer' }}
		onClick={() => updateStatus(data?.id, data?.status)}
	/>

	const { loading: statusLoading, mutate: statusMutate } = useApiMutation(refetch);
	const updateStatus = (id, status) => {
 		const cb = async () => {
			await statusMutate({
				data: {},
				endPoint: apisVendor.unit.statusChange + id,
			});
		};
		popUp(
			status === 'active' ? 'Deactive' : 'Active',
			'Are You Sure?',
			'warning',
			cb
		);
	};
*/

export const useApiMutation = (refetch) => {
	const { logout } = useContext(UseAuth);

	const {
		isLoading: loading,
		mutate,
		error,
	} = useMutation({
		mutationFn: async (data) => {
			return await http.get(data?.endPoint);
		},

		// for global post error
		onError: (error) => {
			// refetch();

			tost(error?.message);
			if (error?.response?.data?.message === 'Unauthenticated.') {
				logout();
			}
		},

		// for global post success
		onSuccess: ({ data }) => {
			refetch();
			data?.message && tost(data?.message);
			if (data?.response?.data?.message === 'Unauthenticated.') {
				logout();
			}
		},
	});

	if (error?.response?.data?.message === 'Unauthenticated.') {
		logout();
	}

	return { loading, mutate };
};

// update version for status update
/*

	const { statusHandler, statusLoading } = useUpdateStatus(refetch);

	(loading ||statusLoading)

	style={{ cursor: 'pointer' }}
	onClick={() =>
		statusHandler(
			data?.status,
			apisVendor.orderSource.statusChange + data?.id
		)
	}
*/
export const useUpdateStatus = (refetch) => {
	const { loading: statusLoading, mutate: statusMutate } =
		useApiMutation(refetch);

	const statusHandler = (status, endPoint) => {
		const cb = async () => {
			await statusMutate({
				data: {},
				endPoint,
			});
		};
		popUp(
			status === 'active' ? 'Deactive' : 'Active',
			'Are You Sure?',
			'warning',
			cb
		);
	};
	return { statusHandler, statusLoading };
};

/* ----------------------------------------------------------------------
    delete api call
    how to  use
   ------------------- 

  =>  import like this 
	    const { loading, willDelete } = useDelete(refetch);
  =>  onClick Function call like this 
        onClick={() => willDelete(`/delete-subcategory/${mainData?.id}`)}
  =>  disable use = {loading}

  => Example
    <Btn
        onClick={() => willDelete(`/delete-subcategory/${mainData?.id}`)}
		icon={'del'}
		text="Delete"
		disabled={loading}
	/>

*/
export const useDelete = (refetch) => {
	const { logout } = useContext(UseAuth);

	const { isLoading: loading, mutate } = useMutation(
		(endpoint) => http.delete(endpoint),
		{
			onError: (error) => {
				refetch();
				tost(error?.message);
				if (error?.response?.data?.message === 'Unauthenticated.') {
					logout();
				}
			},
			onSuccess: ({ data }) => {
				refetch();
				tost(data?.message);
				if (data?.response?.data?.message === 'Unauthenticated.') {
					logout();
				}
			},
		}
	);

	const willDelete = (endpoint) => DeletePopUP(() => mutate(endpoint));

	return {
		loading,
		willDelete,
	};
};

// use delete end
// ----------------------------------------------------------------------
